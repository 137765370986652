<template>
    <div class="outer-container">
        <div class="content-container">
            <div v-bind:class="displayGraphOptions">
                <div v-if="hideheader != 1">
                    <b-form-group>
                        <!-- <div class=" division chart-new xy-check"> -->
                        <div class=" chart-new xy-check">
                            <h5 class="my-2 medium-text">
                                Elements to graph
                            </h5>
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'X-axis field',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                                v-bind:label="floatLabelEdit"
                            >
                                <b-form-select v-model="xaxis">
                                    <option
                                        v-for="ele in xaxisData"
                                        :key="ele"
                                        >{{ ele }}</option
                                    >
                                </b-form-select>
                            </CustomFloatingLabel>
                            <CustomFloatingLabel
                                class="FloatingTool mb-0"
                                :config="{
                                    label: 'Y-axis field',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    @change="minMaxInterval(yAxisChecked)"
                                    v-model="yaxis"
                                >
                                    <template #first>
                                        <!-- <option value="">Y-axis field</option> -->
                                    </template>
                                    <option
                                        v-for="ele in yaxisData"
                                        :key="ele"
                                        >{{ ele }}</option
                                    >
                                </b-form-select>
                            </CustomFloatingLabel>

                            <hr />
                            <CustomFloatingLabel
                                :config="{
                                    label: 'X-axis label',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                                class="mt-3"
                            >
                                <input
                                    v-model="axisDetails[0].axisAlias"
                                    name="yaxi"
                                    autocomplete="off"
                                />
                            </CustomFloatingLabel>
                            <CustomFloatingLabel
                                :config="{
                                    label: 'Y-axis label',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    v-model="axisDetails[1].axisAlias"
                                    name="yaxi"
                                    autocomplete="off"
                                />
                            </CustomFloatingLabel>

                            <b-card no-body class="mb-1 accordion-more">
                                <b-button
                                    class="view-more normal_input headergroup"
                                    block
                                    v-b-toggle.accordion-1
                                    ><span class="when-closed">Expand</span
                                    ><span class="when-open">Collapse</span> X-Y
                                    axis options</b-button
                                >
                                <b-collapse
                                    id="accordion-1"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <axis-edit
                                            :axisDetails="axisDetails"
                                        ></axis-edit>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <hr />
                            <div class="yAxisRange">
                                <b-form-checkbox
                                    v-model="isAutomaticYScale"
                                    style="margin: 0;"
                                >
                                    Automatic Y-Axis Scale
                                </b-form-checkbox>
                                <div v-if="!isAutomaticYScale">
                                    <div class="lblZeroAxis">
                                        Zero axis
                                    </div>
                                    <label class="switch mx-2">
                                        <input
                                            type="checkbox"
                                            v-model="yAxisChecked"
                                            :disabled="yaxis == ''"
                                            @click="
                                                minMaxInterval(!yAxisChecked)
                                            "
                                        />
                                        <span
                                            :class="
                                                yaxis == ''
                                                    ? 'sliderDisable'
                                                    : ''
                                            "
                                            class="slider round"
                                        ></span>
                                    </label>
                                    <CustomFloatingLabel
                                        class="mr-1 ml-4"
                                        :config="{
                                            label: 'Min',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <input
                                            type="number"
                                            v-model="rangeValues.minValue"
                                            autocomplete="off"
                                            class="normal_input"
                                        />
                                    </CustomFloatingLabel>
                                    <CustomFloatingLabel
                                        class="mx-1"
                                        :config="{
                                            label: 'Max',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <input
                                            type="number"
                                            v-model="rangeValues.maxValue"
                                            autocomplete="off"
                                            class="normal_input"
                                        />
                                    </CustomFloatingLabel>
                                    <CustomFloatingLabel
                                        class="mx-1"
                                        :config="{
                                            label: 'Interval',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <input
                                            type="number"
                                            @input="validateDecimal($event)"
                                            v-model="rangeValues.interval"
                                            autocomplete="off"
                                            class="normal_input"
                                        />
                                    </CustomFloatingLabel>
                                </div>
                            </div>
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Series field',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="series"
                                    @change="onChange($event, index, 'main')"
                                >
                                    <template #first>
                                        <!-- <option disabled value="">Series field</option> -->
                                    </template>
                                    <option
                                        v-for="ele in seriesData"
                                        :key="ele"
                                    >
                                        {{ ele }}</option
                                    >
                                </b-form-select>
                            </CustomFloatingLabel>

                            <div
                                class="select-container multipleSeries"
                                v-for="(s, index) in elementSeries"
                                :key="index"
                            >
                                <CustomFloatingLabel
                                    class="FloatingTool"
                                    :config="{
                                        label: 'Series field',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <b-form-select
                                        v-model="s.value"
                                        class="small_drop"
                                        @change="
                                            onChange($event, index, 'rare')
                                        "
                                    >
                                        <option selected value="" disabled
                                            >Series</option
                                        >
                                        <option
                                            @change="getSerisOpt"
                                            :value="ele"
                                            v-for="ele in seriesData"
                                            :key="ele"
                                            >{{ ele }}</option
                                        >
                                    </b-form-select>
                                </CustomFloatingLabel>
                                <a
                                    class="delete"
                                    @click="deleteSeriesElement(index)"
                                ></a>
                            </div>
                            <input
                                type="text"
                                class="normal_input headergroup"
                                @click="addHeaderGroup()"
                                value="Add another series field +"
                                autocomplete="off"
                            />
                            <h5 class="my-4 medium-text">
                                Graph options
                            </h5>
                            <div
                                v-if="graphOptions.length == 0"
                                class="grey-txt"
                            >
                                Select elements to graph before selecting graph
                                options.
                            </div>

                            <div v-else class="regular-text xy-check">
                                Secondary axis scales
                                <b-form-radio-group
                                    v-if="graphOptions.length > 0"
                                    v-model="selectedAxis"
                                    id="radio-group-axissds"
                                    @change="
                                        columnOpen = graph;
                                        onChangeCheckBox(index);
                                    "
                                    name="sub-component"
                                >
                                    <b-form-radio
                                        v-model="selectedAxis"
                                        name="index-radio"
                                        :value="null"
                                        >No secondary axis</b-form-radio
                                    >
                                </b-form-radio-group>
                                <div
                                    v-for="(graph, index) in graphOptions"
                                    :key="index"
                                    class="pt-2"
                                >
                                    <b-form-radio-group
                                        v-model="selectedAxis"
                                        id="radio-group-axis"
                                        @change="
                                            columnOpen = graph;
                                            onChangeCheckBox(index);
                                        "
                                        name="sub-component"
                                    >
                                        <b-form-radio
                                            v-model="selectedAxis"
                                            name="index-radio"
                                            :value="graph"
                                            >{{ graph }}</b-form-radio
                                        >
                                    </b-form-radio-group>
                                    <div
                                        v-if="
                                            showradioCheckbox &&
                                                checkboxOptions != 0 &&
                                                columnOpen == graph
                                        "
                                        id="nav"
                                    >
                                        <ul class="xy-radio">
                                            <li
                                                v-for="(coption,
                                                index) in checkboxOptions[
                                                    index
                                                ]"
                                                :key="index"
                                            >
                                                <b-form-checkbox
                                                    v-model="graphname"
                                                    @change="
                                                        secondarySelected(
                                                            coption
                                                        )
                                                    "
                                                    class="cbox"
                                                    name="index"
                                                    :value="coption"
                                                >
                                                    sxx
                                                    {{ coption }}
                                                </b-form-checkbox>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <h5 class="my-4 medium-text">
                                    Select an option to handle null values
                                </h5>
                                <CustomFloatingLabel
                                    class="FloatingTool"
                                    :config="{
                                        label: 'Null Handling',
                                        scale: false,
                                    }"
                                >
                                    <b-form-select v-model="nullHandler">
                                        <option value="Zero"
                                            >Plot nulls as zero</option
                                        >
                                        <option value="Drop">
                                            Do not plot nulls, connect line to
                                            the next valid point
                                        </option>
                                        <option value="Gap">
                                            Do not plot nulls, disconnect line
                                            between valid points
                                        </option>
                                    </b-form-select>
                                </CustomFloatingLabel>
                                <hr />
                                <h5 class="my-4 medium-text">
                                    Select column to show multiple graphs
                                </h5>
                                <div
                                    class="select-container multipleSeries"
                                    v-for="(s, index) in multipleGraphsArray"
                                    :key="index"
                                >
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Multiple graphs',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="s.value"
                                            @change="
                                                onChange(
                                                    $event,
                                                    index,
                                                    'multiple'
                                                )
                                            "
                                        >
                                            <template #first>
                                                <option value="null"
                                                    >No multiple graphs</option
                                                >
                                            </template>
                                            <option
                                                v-for="ele in seriesData"
                                                :key="ele"
                                            >
                                                {{ ele }}</option
                                            >
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                    <a
                                        class="delete"
                                        v-if="index > 0"
                                        @click="deleteMultiGraphCols(index)"
                                    ></a>
                                </div>

                                <input
                                    type="text"
                                    class="normal_input headergroup"
                                    @click="addMultigraphCol()"
                                    value="Add another column +"
                                    autocomplete="off"
                                />
                                <hr />
                                <b-form-checkbox
                                    v-model="status"
                                    class="cbox pl-4"
                                >
                                    Logarithmic scale
                                </b-form-checkbox>
                            </div>

                            <br />
                            <h5 class="medium-text">
                                Title block preview
                            </h5>
                            <div class="grey-txt">
                                Default settings have been applied.
                            </div>
                            <div class="grey-txt">
                                To modify the style, select "edit title block".
                            </div>
                            <b-row>
                                <div
                                    v-if="titleBlock[0].text"
                                    id="editBlock"
                                    class="block-2 mt-4"
                                >
                                    <div class="preview_logo">
                                        <div
                                            class="side_block pb-0 pt-3 px-3 row m-0 d-flex justify-content-between"
                                        >
                                            <div
                                                class="col-xs-4 col-xs-offset-4 vmiddle w-100-left"
                                            >
                                                <img
                                                    :src="blobPath"
                                                    class="img_title"
                                                    id="scatterImage"
                                                    v-if="blobPath"
                                                />
                                            </div>
                                            <div class="col">
                                                <div
                                                    class="line_block -xs-4"
                                                    v-for="(item,
                                                    index) in titleBlock"
                                                    :key="index"
                                                >
                                                    <div
                                                        v-bind:style="{
                                                            fontSize:
                                                                item.textSize +
                                                                'px',
                                                            color:
                                                                item.textColor,
                                                            fontFamily:
                                                                item.textType,
                                                            fontWeight:
                                                                item.textStyle,
                                                        }"
                                                        :id="
                                                            'previewDatasetGraph' +
                                                                index
                                                        "
                                                    >
                                                        {{ item.text }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="footer-section bor-t-1">
                                                <div
                                                    class="d-flexbox float-left"
                                                >
                                                    {{ footerText }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                            <button
                                @click="gotoTitleblock"
                                class="editblock mt-4"
                            >
                                Edit title block
                            </button>

                            <h5 class="mt-4 medium-text">
                                Template formats
                            </h5>
                            <p class="mb-3">Page layout</p>
                            <b-form-radio-group
                                id="radio-group-1"
                                class="bottom-p-125"
                                v-model="selectedLayout"
                                name="radio-sub-component-2"
                            >
                                <b-form-radio
                                    name="some-radios-2"
                                    v-model="selectedRadio"
                                    value="portrait"
                                    >Portrait</b-form-radio
                                >
                                <b-form-radio
                                    name="some-radios-2"
                                    v-model="selectedRadio"
                                    value="landscape"
                                    >Landscape</b-form-radio
                                >
                            </b-form-radio-group>
                        </div>
                    </b-form-group>
                </div>
            </div>
            <div id="scatterPlotDiv" v-if="displayGraph">
                <scatter-plot
                    :logarithamic="status"
                    :orientation="selectedRadio"
                    :secondaryAxis="secondaryAxisSelections"
                    :graphDetails="graphDetails"
                    :seriesMultiple="seriesMultiple"
                    :filteredColumnId="filteredColumnId"
                    :columnNameSeries="columnNameSeries"
                    :axisDetails="axisDetails"
                    :nullHandler="nullHandler"
                    :isAutomaticYScale="isAutomaticYScale"
                ></scatter-plot>
            </div>

            <ToastMessages
                :showSuccess="showSuccess"
                :showFailure="showFailure"
                :showConnecting="showConnecting"
                :failureToastMessage="failureToastMessage"
                :connectingToastMessage="connectingToastMessage"
                :successToastMessage="successToastMessage"
            />
        </div>
        <div class="footer-container">
            <b-col class="text-right">
                <b-button @click="backtoOption()" class="btn-post footer-btn">
                    Back to graph options
                </b-button>
                <b-button
                    rounded
                    @click="goToScatter"
                    class="buttonSelect footer-btn"
                    :disabled="xaxis == '' || yaxis == '' || series == ''"
                    >Generate graph
                </b-button>
            </b-col>
        </div>
    </div>
</template>

<script>
import { datasetService } from '../../services/dataset.service';
import { reportingService } from '../../services/reporting.service';
import { mapActions, mapState } from 'vuex';
import CustomFloatingLabel from '../CustomFloatingLabel';
import ScatterPlot from './scatterPlot.vue';
import AxisEdit from './AxisEdit.vue';
import ToastMessages from '../ToastMessages.vue';

export default {
    name: 'ScatterPlotSidebar',
    components: {
        CustomFloatingLabel,
        ScatterPlot,
        AxisEdit,
        ToastMessages,
    },
    mixins: [],
    props: {
        datasetId: Number,
        projectIds: String,
    },
    data() {
        return {
            showReportingMenu: false,
            hidetableSelect: false,
            currentSwitchState: false,
            backFromGraph: false,
            renderTitle: false,
            titleBlockDisplay: false,
            filteredColumnId: [],
            axisDetails: [
                {
                    axisName: 'primaryXAxis',
                    axisAlias: '',
                    useDefaultStyle: true,
                    style: {
                        fontWeight: 'Normal',
                        color: '#000000',
                        size: '18',
                        fontFamily: 'Arial',
                    },
                },
                {
                    axisName: 'primaryYAxis',
                    axisAlias: '',
                    useDefaultStyle: true,
                    style: {
                        fontWeight: 'Normal',
                        color: '#000000',
                        size: '18',
                        fontFamily: 'Arial',
                    },
                },
            ],
            graph: null,
            hideheader: 0,
            datasetname: '',
            isClicked: false,
            status: false,
            elements: [],
            datasourceId: '',
            tablename: '',
            blobPath: '',
            selected: null,
            yaxis: '',
            xaxis: '',
            series: '',
            elementSeries: [],
            styleSizeData: [],
            multipleGraphsArray: [{ value: null }],
            graphOptions: [],
            titleBlock: [
                {
                    text: '',
                    dataSet: [],
                    textSize: '',
                    textStyle: '',
                    textColor: '',
                    textType: '',
                    styleGHD: false,
                    TitleId: '',
                    BlockDataset: '',
                    BlockId: '',
                    DatasetId: '',
                    StyleId: '',
                },
            ],
            footerText: '',
            editBlock: '',
            showradioCheckbox: false,
            checkboxOptions: [],
            yaxisData: [],
            xaxisData: [],
            seriesData: [],
            secondaryAxisSelections: [],
            selectedRadio: 'portrait',
            thisfilename: null,
            selectedLayout: 'portrait',
            showPage: false,
            editTitleBlockBtn: false,
            scatter: false,
            clickedFilterState: false,
            yAxisChecked: true,
            rangeValues: {},
            seriesExtra: [],
            columnData: [],
            columnOpen: null,
            graphname: [],
            logarithamic: false,
            generateGraph: true,
            graphDetails: {},
            selectedAxis: null,
            columnNameSeries: {},
            nullHandler: 'Zero',
            floatLabelEdit: null,
            showSuccess: false,
            showFailure: false,
            showConnecting: false,
            failureToastMessage: null,
            connectingToastMessage: null,
            successToastMessage: null,
            isAutomaticYScale: false,
        };
    },
    created() {
        this.hideheader = localStorage.getItem('headerHide');
        this.datasourceId = localStorage.getItem('datasourceId');
        this.tablename = localStorage.getItem('tablename');
        this.getElements();
        this.getTitleBlock();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.IsLandScape) {
                this.selectedRadio = 'landscape';
                this.selectedLayout = 'landscape';
            }
        }
    },
    activated() {
        this.getTitleBlock();
    },
    mounted() {},
    render() {
        return null;
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),

        showGenerateChart(value) {
            this.GenerateChart = value;
        },

        currentEditTitleState(value) {
            this.currentstate = value;
        },
        validateDecimal(event) {
            let value = event.target.value;
            if (value.includes('.')) {
                let parts = value.split('.');
                parts[1] = parts[1].slice(0, 2);
                value = parts.join('.');
                this.rangeValues.interval = value;
            }
        },
        getAllFilters() {
            this.firstLvlFilter = [JSON.parse(this.filterOptions)];
            let CumulativeArr = [];

            const arrayHashmap = this.secondLvlFilter.reduce((obj, item) => {
                if (obj[item.ColumnName]) {
                    obj[item.ColumnName].ColumnNameValues.push(
                        item.filterValue
                    );
                    if (obj[item.ColumnName].filterValue != '') {
                        obj[item.ColumnName].ColumnNameValues = [];
                    }
                } else {
                    obj[item.ColumnName] = { ...item };
                    obj[item.ColumnName].ColumnNameValues.push(
                        item.filterValue
                    );

                    if (obj[item.ColumnName].filterCondition == 'notequal') {
                        obj[item.ColumnName].filterCondition = 'notin';
                        obj[item.ColumnName].filterValue = '';
                    } else if (
                        obj[item.ColumnName].filterCondition == 'equal'
                    ) {
                        obj[item.ColumnName].filterCondition = 'in';
                        obj[item.ColumnName].filterValue = '';
                    }
                    if (obj[item.ColumnName].filterValue != '') {
                        obj[item.ColumnName].ColumnNameValues = [];
                    }

                    CumulativeArr.push(obj);
                }
                return obj;
            }, {});

            const mergedArray = Object.values(arrayHashmap);

            if (mergedArray && mergedArray.length > 0) {
                mergedArray.forEach((element) => {
                    this.firstLvlFilter.datastring.push(element);
                });
            }
            localStorage.setItem(
                'gridFilter',
                JSON.stringify(this.firstLvlFilter.datastring)
            );
        },

        openXYGraph() {
            this.hidetableSelect = true;
            this.openNewTable = false;
            this.showDataset = true;
            this.datasetNameHeader = localStorage.getItem('displayName');
            this.tabs.push(localStorage.getItem('datasetName'));
            this.applyFilter(true);
            this.reportingTab.push(this.reportingTabcounter++);
            this.step = 1;
            this.backFromGraph = true;
            localStorage.removeItem('openXYGraph');
        },

        openChartElement(GenerateChart) {
            this.showDataset = true;
            this.getAllFilters();
            this.showGenerateChart(GenerateChart);

            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({ name: 'chartelement' });
                window.open(routeData.href, '_blank');
            } else {
                if (!GenerateChart) {
                    this.reportingTab.push(this.reportingTabcounter++);
                    this.showPage = GenerateChart;
                    this.$refs['permission-modal'].showPermissionModal();
                } else {
                    if (this.scatterPlotTab === -1) {
                        this.reportingTab.push(this.reportingTabcounter++);
                        setTimeout(() => {
                            this.step =
                                this.tooltab.length + this.reportingTab.length;
                            this.scatterPlotTab =
                                this.tooltab.length + this.reportingTab.length;
                        });
                    } else {
                        this.step =
                            this.tooltab.length + this.reportingTab.length;
                    }
                }
            }
        },

        getBrowserType() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (
                (navigator.userAgent.indexOf('Edg') ||
                    navigator.userAgent.indexOf('Edge')) != -1
            ) {
                return 'Edge';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                //IF IE > 10
                return 'IE';
            } else {
                return 'unknown';
            }
        },
        print() {
            this.editBlock = !document.getElementById('editBlock')
                ? ''
                : document.getElementById('editBlock').innerHTML;
            this.graphDetails = {
                xaxisdata: this.xaxis,
                yaxisdata: this.yaxis,
                seriesdata: this.graphOptions.toString(),
                minValue: this.rangeValues.minValue,
                maxValue: this.rangeValues.maxValue,
            };
            const modal = document.getElementById('scatterPlotDiv');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');
            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }

            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            var userBrowser = this.getBrowserType();
            var topMargin = '0';
            var leftMargin = '0';
            switch (userBrowser) {
                case 'Chrome':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
                case 'Firefox':
                    topMargin = '0.5in';
                    leftMargin = '0';
                    break;
                case 'Edge':
                    topMargin = '0.1in';
                    leftMargin = '0.1in';
                    break;
            }
            css = '';
            if (this.selectedRadio == 'portrait') {
                css =
                    '@page { size: portrait; } @media print {.hide-graph{display: block;} #print .chartcontainer {transform: translateY(18%) !important;}';
            } else {
                css +=
                    '@page { size: landscape; } @media print {.hide-graph{display: block;} #print .chartcontainer {transform: translateY(11%) !important;}';
            }
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            setTimeout(function() {
                window.print();
            }, 500);
        },
        pagePermit() {
            this.showPage = true;
            this.editTitleBlockBtn = true;
            this.scatter = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('GenerateChartElement')) {
                    vm.showPage = true;
                }
                if (element.PageName.includes('TitleBlock')) {
                    vm.editTitleBlockBtn = true;
                }
                if (element.PageName.includes('scatterPlot')) {
                    vm.scatter = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        clickedFilter(value) {
            this.clickedFilterState = !value;
        },
        minMaxInterval(yAxisChecked) {
            this.rangeValues = {};
            if (this.yaxis != '') {
                datasetService
                    .getminMaxInterval(
                        this.projectIds,
                        this.datasetId,
                        this.yaxis,
                        yAxisChecked,
                        JSON.parse(this.filterOptions)
                    )
                    .then((res) => {
                        this.rangeValues = {
                            minValue: res.data[0],
                            maxValue: res.data[1],
                            interval: res.data[2],
                        };
                    });
            } else {
                this.rangeValues.minValue = this.rangeValues.maxValue = this.rangeValues.interval =
                    '';
            }
        },
        getElements() {
            datasetService
                .getDataforChartelement(this.datasetId)
                .then((res) => {
                    this.elements = res.data;
                    this.elements.forEach((element) => {
                        if (
                            element.AttributeType == 'Default' &&
                            element.DatasetColumn != null
                        ) {
                            if (
                                element.ToolAttribute.AttributeName === 'Y-axis'
                            ) {
                                this.yaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'X-axis'
                            ) {
                                this.xaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'Series'
                            ) {
                                this.seriesData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                                if (element.DatasetColumn.ColumnAlias) {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnAlias
                                    ] = element.DatasetColumn.ColumnName;
                                } else {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnName
                                    ] = element.DatasetColumn.ColumnName;
                                }
                            }
                        }
                    });
                    this.elements.forEach((element) => {
                        if (
                            element.AttributeType != 'Default' &&
                            element.DatasetColumn != null
                        ) {
                            if (
                                element.ToolAttribute.AttributeName === 'Y-axis'
                            ) {
                                this.yaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'X-axis'
                            ) {
                                this.xaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'Series'
                            ) {
                                this.seriesData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                                if (element.DatasetColumn.ColumnAlias) {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnAlias
                                    ] = element.DatasetColumn.ColumnName;
                                } else {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnName
                                    ] = element.DatasetColumn.ColumnName;
                                }
                            }
                        }
                    });
                    this.yaxisData = [...new Set(this.yaxisData)];
                    this.xaxisData = [...new Set(this.xaxisData)];
                    this.seriesData = [...new Set(this.seriesData)];
                    this.xaxis = this.xaxisData[0];
                    this.yaxis = this.yaxisData[0];
                    this.series = this.seriesData[0];
                    this.graphOptions[0] = this.seriesData[0];

                    datasetService
                        .GetDistinctFilteredvalues(
                            this.projectIds,
                            this.datasetId,
                            this.seriesData[0],
                            JSON.parse(this.filterOptions)
                        )
                        .then((res) => {
                            this.columnData = res.data;
                            this.checkboxOptions[0] = this.columnData;
                            this.checkboxOptions[0].forEach(
                                (item) => (this.cboxOptions = item.graphname)
                            );
                        })
                        .catch((err) => {
                            if (
                                err.response.data.Message ==
                                'ForbiddenSQLInjection'
                            ) {
                                this.failureToastMessage =
                                    'Forbidden SQL Injection';
                                this.showFailure = true;
                                setTimeout(() => {
                                    this.showFailure = false;
                                }, 5000);
                            }
                        });
                    let loadcheck = true;
                    this.minMaxInterval(loadcheck);
                });
        },
        addHeaderGroup() {
            this.elementSeries.push({ value: '' });
        },
        addMultigraphCol() {
            this.multipleGraphsArray.push({ value: null });
        },
        deleteSeriesElement(id) {
            this.elementSeries.splice(id, 1);
            this.graphOptions.splice(id + 1, 1);
            this.checkboxOptions.splice(id + 1, 1);
        },
        deleteMultiGraphCols(id) {
            this.multipleGraphsArray.splice(id, 1);
        },
        getSerisOpt(evt) {},
        onChangeCheckBox(evt) {
            if (this.selectedAxis === null) {
                this.checkboxUnselected(evt);
            } else {
                this.radioSelected(evt);
            }
        },
        radioSelected(evt) {
            this.selectedRadio = this.selectedLayout;
            localStorage.setItem('scaleIndex', evt);
            this.showradioCheckbox = true;
            this.secondaryAxisSelections = [];
            for (let p in this.checkboxOptions[0]) {
                this.graphname.splice(p, 1);
            }
        },
        checkboxUnselected(evt) {
            localStorage.setItem('scaleIndex', 0);
            this.showradioCheckbox = false;
            for (let p in this.checkboxOptions[0]) {
                this.graphname.splice(p, 1);
            }
        },
        secondarySelected(evt) {
            if (this.secondaryAxisSelections.indexOf(evt) > -1) {
                this.secondaryAxisSelections.splice(
                    this.secondaryAxisSelections.indexOf(evt),
                    1
                );
            } else {
                this.secondaryAxisSelections.push(evt);
            }
        },
        onChange(event, index, type) {
            if (type == 'main') {
                this.graphOptions[0] = event;
                datasetService
                    .GetDistinctFilteredvalues(
                        this.projectIds,
                        this.datasetId,
                        event,
                        JSON.parse(this.filterOptions)
                    )
                    .then((res) => {
                        this.columnData = res.data;
                        this.checkboxOptions[0] = this.columnData;
                        this.checkboxOptions[0].forEach(
                            (item) => (this.cboxOptions = item.graphname)
                        );
                    });
            } else if (type === 'multiple') {
                this.multipleGraphsArray[index].value = event;
            } else {
                this.graphOptions[index + 1] = event;
                datasetService
                    .GetDistinctFilteredvalues(
                        this.projectIds,
                        this.datasetId,
                        event,
                        JSON.parse(this.filterOptions)
                    )
                    .then((res) => {
                        this.columnData = res.data;
                        this.checkboxOptions[index + 1] = this.columnData;
                        this.checkboxOptions[index + 1].forEach(
                            (item) => (this.cboxOptions = item.graphname)
                        );
                    });
            }
            this.$forceUpdate();
        },

        getCodesGraphLocation() {
            datasetService
                .filterDataset()
                .then((res) => (this.siteOptions = res.data));
        },
        getTitleBlock() {
            this.titleBlock = [
                {
                    text: '',
                    dataSet: [],
                    textSize: '',
                    textStyle: '',
                    textColor: '',
                    textType: '',
                    styleGHD: false,
                    TitleId: '',
                    BlockDataset: '',
                    BlockId: '',
                    DatasetId: '',
                    StyleId: '',
                },
            ];
            reportingService.getTitleBlock(this.datasetId).then((res) => {
                this.titleBlockData = res.data;
                if (this.titleBlockData.length == 0) {
                    localStorage.removeItem('editBlock');
                } else {
                    this.titleBlockData.forEach((element, index) => {
                        if (this.titleBlock.length < 5) {
                            if (index === 0) {
                                this.footerText = element.FooterText;
                                this.titleBlock[
                                    index
                                ].text = element.BlockText.replace(
                                    /&lt;/g,
                                    '<'
                                ).replace(/&gt;/g, '>');
                                this.blobPath = element.BlobPath
                                    ? element.BlobPath
                                    : '';
                                this.titleBlock[index].textSize =
                                    element.FontSize.Size;
                                this.titleBlock[index].textStyle =
                                    element.FontStyle.Style;
                                this.titleBlock[index].textColor =
                                    element.StyleColor;
                                this.titleBlock[index].textType =
                                    element.FontType.Type;
                                this.titleBlock[index].TitleBlockId =
                                    element.TitleBlockId;
                                this.titleBlock[index].BlockDataset =
                                    element.BlockDataset;
                                this.titleBlock[index].DatasetId =
                                    element.DatasetId;
                                this.titleBlock[index].StyleId =
                                    element.StyleId;
                                this.titleBlock[index].styleGHD =
                                    element.FontType.IsDefault &&
                                    element.FontStyle.IsDefault &&
                                    element.FontSize.IsDefault
                                        ? true
                                        : false;
                            } else {
                                this.titleBlock.push({
                                    text: element.BlockText.replace(
                                        /&lt;/g,
                                        '<'
                                    ).replace(/&gt;/g, '>'),
                                    dataSet: [],
                                    textSize: element.FontSize.Size,
                                    textStyle: element.FontStyle.Style,
                                    textColor: element.StyleColor,
                                    textType: element.FontType.Type,
                                    styleGHD:
                                        element.FontType.IsDefault &&
                                        element.FontStyle.IsDefault &&
                                        element.FontSize.IsDefault
                                            ? true
                                            : false,
                                });
                            }
                            let blockText = element.BlockText.replace(
                                /&lt;/g,
                                '<'
                            ).replace(/&gt;/g, '>');
                            let datasetString = blockText.match(/<<[\w\d]+>>/g);
                            let datasetString1 = blockText.match(
                                /<<[\w\d]+>>/g
                            );
                            if (datasetString) {
                                for (let r = 0; r < datasetString.length; r++) {
                                    datasetString[r] = datasetString[r].replace(
                                        /[<>]/g,
                                        ''
                                    );
                                }

                                let previewContent = blockText;

                                for (let v = 0; v < datasetString.length; v++) {
                                    datasetService
                                        .GetDistinctFilteredvalues(
                                            this.projectIds,
                                            this.datasetId,
                                            datasetString[v],
                                            JSON.parse(this.filterOptions)
                                        )
                                        .then((res) => {
                                            let columnData = res.data;
                                            previewContent = previewContent.replace(
                                                datasetString1[v],
                                                '<span id="new_btn' +
                                                    index +
                                                    v +
                                                    '" class="new_btn' +
                                                    index +
                                                    v +
                                                    '" contenteditable="false">' +
                                                    columnData.join(', ') +
                                                    '</span>'
                                            );
                                            document.getElementById(
                                                'previewDatasetGraph' + index
                                            ).innerHTML = previewContent;
                                        })
                                        .catch((err) => {
                                            if (
                                                err.response.data.Message ==
                                                'ForbiddenSQLInjection'
                                            ) {
                                                this.failureToastMessage =
                                                    'Forbidden SQL Injection';
                                                this.showFailure = true;
                                                setTimeout(() => {
                                                    this.showFailure = false;
                                                }, 5000);
                                            }
                                        });
                                }
                            }
                        }
                    });
                }
            });
        },
        gotoTitleblock() {
            this.$parent.displayedToolTab = 'Title Block';
        },
        goToXYAxisEdit() {
            this.$emit('currentEditTitleState', this.clickedFilterState);
            this.$root.$refs.B.editXYAxisTabOpen();
        },
        backtoOption() {
            this.generateGraph = true;
            setTimeout(() => {
                if (document.getElementById('editBlock'))
                    document.getElementById(
                        'editBlock'
                    ).innerHTML = localStorage.getItem('editBlock')
                        ? localStorage.getItem('editBlock')
                        : '';
            }, 2000);

            let radioSelect = localStorage.getItem('scaleIndex');
            this.radioSelected(radioSelect);
        },
        goToScatter() {
            this.editBlock = !document.getElementById('editBlock')
                ? ''
                : document.getElementById('editBlock').innerHTML;
            localStorage.setItem('editBlock', this.editBlock);
            this.generateGraph = false;

            localStorage.setItem(
                'Elements',
                JSON.stringify(this.elementSeries)
            );
            localStorage.setItem('series', this.series);
            localStorage.setItem(
                'graphOptions',
                JSON.stringify(this.graphOptions)
            );
            localStorage.setItem(
                'checkboxOptions',
                JSON.stringify(this.checkboxOptions)
            );
            localStorage.setItem('columnOpen', this.columnOpen);
            localStorage.setItem('graphname', JSON.stringify(this.graphname));
            localStorage.setItem('yAxisChecked', this.yAxisChecked);
            localStorage.setItem(
                'rangeValues',
                JSON.stringify(this.rangeValues)
            );
            localStorage.setItem('selectedRadio', this.selectedRadio);
            this.graphDetails = {
                xaxisdata: this.xaxis,
                yaxisdata: this.yaxis,
                seriesdata: this.graphOptions.toString(),
                minValue: this.rangeValues.minValue,
                maxValue: this.rangeValues.maxValue,
            };
            localStorage.setItem('minValue', this.rangeValues.minValue);
            localStorage.setItem('maxValue', this.rangeValues.maxValue);
            localStorage.setItem('interval', this.rangeValues.interval);
            localStorage.setItem('orientation', this.selectedRadio);
        },
    },
    computed: {
        ...mapState({
            filterOptions: (state) => state.gridFilters.gridFilter,
        }),
        seriesMultiple() {
            let multiGrapArr = [];
            this.multipleGraphsArray.forEach((col) => {
                if (col.value != null) {
                    multiGrapArr.push(col.value);
                }
            });
            return multiGrapArr;
        },
        displayGraph() {
            if (this.generateGraph) {
                return false;
            } else {
                return true;
            }
        },
        displayGraphOptions() {
            if (!this.generateGraph) {
                return 'hide-graph';
            } else {
                return 'show-graph';
            }
        },
    },
    watch: {
        async datasetId(newVal, oldVal) {
            if (newVal != oldVal) {
                this.yaxis = '';
                this.xaxis = '';
                this.yaxisData = [];
                this.xaxisData = [];
                this.seriesData = [];
                this.graphOptions = [];
                this.isAutomaticYScale = false;
                this.getElements();
                this.getTitleBlock();
                if (this.$store.state.store.portalsettings !== undefined) {
                    if (this.$store.state.store.portalsettings.IsLandScape) {
                        this.selectedRadio = 'landscape';
                        this.selectedLayout = 'landscape';
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.outer-container {
    position: relative;
    float: left;
    height: calc(100vh - 200px) !important;
    width: 100%;
    overflow: hidden;
    direction: ltr !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 10px;
}
.content-container {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100vh - 300px) !important;
    overflow: scroll;
    padding-left: 20px;
}
.footer-container {
    align-self: flex-end;
    float: right;
    width: 100%;
    height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    text-align: center;
}

.normal_input {
    height: 48px !important;
    border-radius: 3px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;

    width: 500px;
    text-align: center;
    cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-top: 0px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}

.hide-graph {
    display: none;
}
.show-graph {
    display: block;
}

.editblock {
    background-color: #006395 !important;
    padding: 11px 10px !important;
    border-radius: 3px;
    border: 0px !important;
    min-width: 135px;
    height: 48px;
    color: #fff;
    &:hover {
        background-color: #00496d !important;
    }

    &:disabled {
        background-color: #6c757d !important;
    }
}

.footer-btn {
    height: 48px;
    min-width: 161px;
    margin-right: 24px;
    margin-top: 24px;
}

/* style for title block preview */
.block-2 {
    .preview_logo {
        .side_block {
            display: block;
            border-radius: 3px;
            min-height: 75px;
            height: auto;
            width: 499px;
            position: relative;
            float: left;
            box-shadow: 0 1px 1px 1px #dee2e6 !important;
            .img_title {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            .line_block {
                text-align: right;
                span {
                    font-size: 14px;
                    padding-top: 10px;
                    padding-right: 5px;
                }
            }
            .footer-section {
                border-top: 1px solid #dee2e6;
                height: auto;
                position: relative;
                bottom: 0;
                width: 100%;
                span {
                    text-align: left;
                    font-size: 12px;
                }
                span.center {
                    text-align: center;
                }
            }
        }
    }
}
#scatterPlotDiv .block-2 .preview_logo .side_block {
    box-shadow: none !important;
}
</style>
